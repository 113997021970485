import React from 'react';
import style from './Interests.module.css';

function Interests() {
  return (
    <div className={style.cont}>
      <span>Interests</span><br /><br />
      <ul>
          
      </ul>
    </div>
  );
}

export default Interests;
